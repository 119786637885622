import Router from "next/router";
import { POSTCONVERT_URL } from "./constants";
import { getContact, getValue, leadSubmission } from "./Lead";
import { t } from "i18next";

function insertAlsoBuying(array, item, index) {
  // Check if key already exists in the array
  const existingIndex = array.indexOf(item);

  if (existingIndex !== -1) {
    // Remove key from its current position
    array.splice(existingIndex, 1);
  }

  // Insert "also-buying" after index 3
  array.splice(index + 1, 0, item);

  return array;
}

function moveArrayElement(arr, fromIndex, toIndex) {
  // Check if fromIndex and toIndex are within bounds
  if (
    fromIndex < 0 ||
    fromIndex >= arr.length ||
    toIndex < 0 ||
    toIndex >= arr.length
  ) {
    throw new Error("Index out of bounds");
  }

  // Remove the element from the original position
  const element = arr.splice(fromIndex, 1)[0];

  // Insert the element at the new position
  arr.splice(toIndex, 0, element);

  return arr;
}

function removeStringFromArray(arr, str) {
  return arr?.filter((item) => item !== str);
}

const routing = (experiment) => {
  let buyer = [
    "/",
    "price",
    "location",
    "timeframe",
    "mortgage",
    "name",
    "email",
    "phone",
    "mrc",
  ];

  if (getValue("country") === "CA") {
    buyer.splice(5, 0, "rocket");
  }

  let seller = ["/", "price", "property", "address", "name", "email", "phone"];

  let both = [
    "/",
    "price",
    "property",
    "address",
    "also-buying",
    "name",
    "email",
    "phone",
    "mrc",
  ];

  if (experiment?.includes("location_first")) {
    buyer = moveArrayElement(buyer, 2, 1);
    seller = moveArrayElement(seller, 3, 1);
    both = moveArrayElement(both, 3, 1);
  }

  return {
    buyer,
    seller,
    both,
  };
};

export const PROGRESSBAR_ROUTING = (experiment) => {
  let routes = routing(experiment);
  routes.buyer = removeStringFromArray(routes?.buyer, "mrc");
  routes.buyer = removeStringFromArray(routes?.buyer, "rocket");
  routes.both = removeStringFromArray(routes?.both, "mrc");
  routes.both = removeStringFromArray(routes?.both, "rocket");

  return routes;
};

export function isNextSubmit(experiment) {
  const { curPage, leadType, step, prevStep } = getCurrentPage(experiment);
  const nextPage = curPage + 1;
  const nextRoute = leadType
    ? routing(experiment)[leadType][nextPage]
    : "price";
  const prevRoute = leadType
    ? routing(experiment)[leadType][prevStep]
    : "price";

  const name = getContact("firstName");
  const email = getContact("email");
  const phone = getContact("phone");

  return (
    nextRoute === "name" &&
    name &&
    email &&
    phone &&
    leadType !== "both" &&
    prevRoute !== "email" &&
    prevRoute !== "phone"
  );
}

export async function nextPage(experiment, legaleseText) {
  const { curPage, leadType, step, prevStep } = getCurrentPage(experiment);
  const nextPage = curPage + 1;
  const nextRoute = routing(experiment)[leadType][nextPage];
  const prevRoute = routing(experiment)[leadType][prevStep];

  setCompletedStep(experiment);

  const name = getContact("firstName");
  const email = getContact("email");
  const phone = getContact("phone");

  if (
    nextRoute === "name" &&
    name &&
    email &&
    phone &&
    leadType !== "both" &&
    prevRoute !== "email" &&
    prevRoute !== "phone"
  ) {
    const pushUrl = POSTCONVERT_URL({
      uuid: window.localStorage.getItem("current-email-uuid"),
    });

    const consentBlock = {};
    if (legaleseText) {
      consentBlock["consent"] = {
        consent_text_original:
          leadType === "buyer"
            ? `By clicking "Yes", you consent, acknowledge and agree to our Terms of Use and Privacy Policy which includes binding arbitration and consent to receive electronic communications, you are providing express written consent for RealEstateAgents.com and our participating agents as well as Rocket Mortgage Canada to contact you at the phone number you provided via calls and/or SMS, MMS using an automated dialing system to provide agent info. Even if your number is listed on an internal, corporate, state or federal Do-Not-Call list. You may revoke this consent any time. Consent is not a condition for our service.`
            : `By clicking "${t(
                "accept"
              )}" above, you consent, acknowledge and agree to our Terms of Use and Privacy Policy 
        which includes binding arbitration and consent to receive electronic
      communications, you are providing express written consent for
      RealEstateAgents.com and our participating agents to contact you at the
      phone number you provided via calls and/or SMS, MMS using an automated
      dialing system to provide agent info. even if your number is listed on an
      internal, corporate, state or federal Do-Not-Call list. You may revoke
      this consent any time. Consent is not a condition for our service.`,
        consent_text_shown: legaleseText,
        text_consent_given: true,
        url: window.location.href,
        phone_consent_given: true,
        email_consent_given: true,
      };
    }
    const res = await leadSubmission(consentBlock);
    if (res.ok) {
      Router.push(pushUrl);
    } else {
      window.location.reload();
    }
  } else {
    Router.push({ pathname: `/${leadType}/${nextRoute}`, query: Router.query });
  }
}

export function pushToAgentsApp(url) {
  Router.push(url);
}

export function setCompletedStep(experiment) {
  const { curPage, leadType, step } = getCurrentPage(experiment);

  const completedSteps = getCompletedSteps();
  completedSteps[leadType][curPage === 0 ? "/" : step] = true;
  window.localStorage.setItem("completedSteps", JSON.stringify(completedSteps));
}

export function prevPage() {
  Router.back();
  // const { curPage, leadType } = getCurrentPage();
  // const nextPage = curPage - 1;
  // const nextRoute = routing(leadType][nextPage];
  // Router.push(nextRoute === "/" ? "/" : `/${leadType}/${nextRoute}`);
}

export function isPageValid(experiment) {
  const { prevStep, leadType, step, lastCompletedStep } =
    getCurrentPage(experiment);

  const completedSteps = getCompletedSteps();

  if (step === "price") {
    window.localStorage.setItem("leadType", leadType);
    completedSteps[leadType][prevStep] = true;
  }

  const pageValid = completedSteps[leadType]
    ? completedSteps[leadType][prevStep]
    : false;

  if (!pageValid && step) {
    Router.replace(
      lastCompletedStep === "/" ? "/" : `/${leadType}/${lastCompletedStep}`
    );
  }
}

export function getStepIndex(experiment) {
  const { curPage } = getCurrentPage(experiment);
  return curPage;
}

export function getCompletedSteps() {
  const completedSteps = window.localStorage.getItem("completedSteps");
  const jsonCompletedSteps = completedSteps
    ? JSON.parse(completedSteps)
    : { seller: {}, buyer: {}, both: {} };

  return jsonCompletedSteps;
}

export const getCurrentPage = (experiment) => {
  if (
    !process.browser ||
    (!Router.query.step && !window.localStorage.getItem("leadType"))
  ) {
    return {};
  }

  const leadType =
    Router.query.leadType || window.localStorage.getItem("leadType");
  const step = Router.query.step || "/";
  const r = Router;

  const currentPath = Router.pathname;
  const curPage =
    currentPath === "/" ? 0 : routing(experiment)[leadType]?.indexOf(step);

  const prevStep =
    currentPath === "/"
      ? "/"
      : routing(experiment)[leadType]
      ? routing(experiment)[leadType][curPage - 1]
      : 0;

  const completedSteps = getCompletedSteps();
  const completedKeys = completedSteps[leadType]
    ? Object.keys(completedSteps[leadType])
    : [];

  let lastCompletedPage = 0;
  for (let i = 0; i < completedKeys.length; i++) {
    const completedPage = routing(experiment)[leadType].indexOf(
      completedKeys[i]
    );
    if (completedPage > lastCompletedPage) {
      lastCompletedPage = completedPage;
    }
  }
  const lastCompletedStep = routing(experiment)[leadType]
    ? routing(experiment)[leadType][lastCompletedPage + 1]
    : "";
  return { curPage, leadType, step, prevStep, lastCompletedStep };
};
