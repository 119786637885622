import { useRouter } from "next/router";
import { useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import parser from "parse-address";
import { isMobile as isMobileDetect } from "react-device-detect";
import panelStyles from "/components/experiments/reskin/organisms/Panel.module.scss";

import { getValue, resetAddressInfo, saveValue } from "/config/Lead";
import Button from "/components/experiments/reskin/molecules/Button";
import styles from "./styles/Autocomplete.module.scss";
import contactStyles from "../contact/styles/Contact.module.scss";
import { createFunnelEvent, createFunnelRequest } from "/config/Analytics";
import { ASSET_BASE_URL } from "/config/constants";
import GoogleAutocomplete from "./GoogleAutocomplete";
import { generateApiUrl, POST_CONFIG } from "/config/fetch";

import { prevPage } from "/config/PageRouting";
import { isNextSubmit } from "../../../../../config/PageRouting";
import { useExperiment } from "../../../../../contexts/ExperimentContext";
import Legalese from "../../../../Legalese";
import { StyleSheet, css } from "aphrodite";

export const GOOGLE_API_KEY = "AIzaSyAG__aHmwJRCg59vyZb1rrEXW8L0zuKDik";

export const CONDO_ENUM = "Condominium";

export const geocode = (address, callback) => {
  const geocoder = new google.maps.Geocoder();
  geocoder.geocode(
    {
      address,
    },
    callback
  );
};

export const translateGoogleToAddress = (place) => {
  let city = "";
  let state = "";
  let zip = "";
  let apt = "";
  let streetNumber = "";
  let streetName = "";
  let street = "";
  let country = "";
  const addressComponents = place.address_components;
  const latLng = [
    place.geometry?.location?.lat(),
    place.geometry?.location?.lng(),
  ];

  for (let i = 0; i < addressComponents.length; i++) {
    let currentPlace = addressComponents[i];
    let types = currentPlace.types;
    if (types.includes("administrative_area_level_1")) {
      state = currentPlace.short_name;
    }

    if (types.includes("subpremise")) {
      apt = ` APT ${currentPlace.long_name}`;
    }

    if (types.includes("street_number")) {
      street = currentPlace.long_name;
      streetNumber = currentPlace.long_name;
    }

    if (types.includes("route")) {
      street += " " + currentPlace.long_name;
      streetName = currentPlace.long_name;
    }

    if (types.includes("country")) {
      country = currentPlace.short_name;
    }

    if (!city && types.includes("locality")) {
      city = currentPlace.long_name;
    }

    if (!city && types.includes("administrative_area_level_3")) {
      city = currentPlace.long_name;
    }

    if (!city && types.includes("administrative_area_level_2")) {
      city = currentPlace.long_name;
    }

    if (!zip && types.includes("postal_code")) {
      zip = currentPlace.long_name;
    }
  }

  return {
    city,
    state,
    zip,
    apt,
    streetNumber,
    streetName,
    country,
    street,
    latLng,
  };
};

export default function LocationAutocomplete({
  leadType,
  onStepCompleted,
  defaultValue,
  onZipChange,
}) {
  const defaultCondo = getValue("unitNumber");
  const [loading, setLoading] = useState(false);
  const [zipBackup, setZipBackup] = useState("");
  const [address, setAddress] = useState("");
  const [error, setError] = useState("");
  const [needsZip, setNeedsZip] = useState(false);
  const [isMobile, setIsMobile] = useState(isMobileDetect);

  const currentAddress = useRef();
  const buttonDisabledRef = useRef();
  const geocodedZipBackup = useRef();
  const autocompleteInterval = useRef();
  const curDropdownResults = useRef();
  const unitRef = useRef();
  const unitFocusRef = useRef();
  const pushedToNextStep = useRef();
  const selectEnterSet = useRef();
  const { t } = useTranslation(["translation", "common"]);
  const router = useRouter();

  const { experiment } = useExperiment();
  const isSellerPath = { seller: true, both: true }[leadType];
  const isBuyerPath =
    router.query.step === "location" || router.query.step === "also-buying";
  const legaleseRef = useRef();

  const isSubmit = isNextSubmit(experiment);

  useEffect(() => {
    if (!selectEnterSet.current) {
      selectEnterSet.current = true;
    }

    setIsMobile(isMobileDetect);
  }, []);

  useEffect(() => {
    if (!needsZip && isSellerPath) {
    } else {
      clearInterval(autocompleteInterval.current);
    }

    return () => {
      clearInterval(autocompleteInterval.current);
    };
  }, [needsZip, isMobile, isSellerPath]);

  useEffect(() => {
    onZipChange && onZipChange(needsZip);
  }, [needsZip, onZipChange]);

  const getDropdownResults = () => {
    const pacContainers = document.getElementsByClassName("pac-container");
    const dropdown = pacContainers[pacContainers.length - 1];
    const dropdownResults = [];

    for (let i = 0; i < dropdown.children.length; i++) {
      const curChild = dropdown.children[i];
      dropdownResults.push(
        curChild.children[1].textContent +
          " " +
          curChild.children[2].textContent
      );
    }
    return dropdownResults;
  };

  const onPlaceSelected = (place, runNumber, noFunnelEvent, zipGeocode) => {
    if (!place || !place.address_components) {
      return null;
    }

    const logicalPlaces = translateGoogleToAddress(place);
    let fullSuggestion;

    if (logicalPlaces.country) {
      const notUsaOrCa = !["us", "ca"].includes(
        logicalPlaces.country.toLowerCase()
      );
      if (notUsaOrCa) {
        setError(t("signup02.address-autocomplete.usaorca"));
        return;
      }
      saveValue("country", logicalPlaces.country);
    }

    if (
      router.query.step === "location" ||
      router.query.step === "also-buying"
    ) {
      if (!logicalPlaces.city) {
        setError(t("signup02.address-autocomplete-buy.no_location"));
        saveValue("address", null);
        return;
      }

      if (logicalPlaces.state) {
        saveValue(
          router.query.step === "also-buying" ? "buyer_state" : "state",
          logicalPlaces.state
        );
      }
      if (logicalPlaces.city) {
        saveValue(
          router.query.step === "also-buying" ? "buyer_city" : "city",
          logicalPlaces.city
        );
      }

      if (
        router.query.leadType === "buyer" ||
        router.query.leadType === "both"
      ) {
        fullSuggestion = place.formatted_address;
      } else {
        fullSuggestion =
          logicalPlaces.city &&
          logicalPlaces.state &&
          logicalPlaces.city + ", " + logicalPlaces.state;
      }
    } else {
      fullSuggestion =
        logicalPlaces.street &&
        logicalPlaces.city &&
        logicalPlaces.state &&
        logicalPlaces.street +
          ", " +
          logicalPlaces.city +
          ", " +
          logicalPlaces.state;

      if (logicalPlaces.state) saveValue("state", logicalPlaces.state);
      if (logicalPlaces.city) saveValue("city", logicalPlaces.city);
      if (logicalPlaces.streetName)
        saveValue("streetName", logicalPlaces.streetName);
      if (logicalPlaces.streetNumber)
        saveValue("streetNumber", logicalPlaces.streetNumber);
      if (logicalPlaces.zip) saveValue("zip", logicalPlaces.zip);
      if (logicalPlaces.latLng) saveValue("latLng", logicalPlaces.latLng);
    }

    if (fullSuggestion) {
      if (router.query.step === "also-buying") {
        saveValue("buyer_address", fullSuggestion);
      } else {
        saveValue("address", fullSuggestion);
      }
    } else if (!zipGeocode) {
      if (router.query.step === "also-buying") {
        saveValue("buyer_address", address);
      } else {
        saveValue("address", address);
      }
    }

    if (!noFunnelEvent) {
      createFunnelEvent({
        eventType: `${router.query.leadType}_location_geocode`,
        step: "location",
        leadType: router.query.leadType,
        details: {
          results: [place],
          translatedResults: logicalPlaces,
          input: currentAddress.current,
          dropdownResults: curDropdownResults.current,
        },
      });
    }

    sendGeocode({ google_response: place });

    handleFinish(runNumber);
  };

  useEffect(() => {
    pushedToNextStep.current = false;
  }, [defaultValue]);

  const sendGeocode = ({ google_response }) => {
    const geocode_request_uuid = uuidv4();
    window.localStorage.setItem("geocode_request_uuid", geocode_request_uuid);
    if (router.query.leadType === "both") {
      window.localStorage.setItem(
        `geocode_request_uuid_${isBuyerPath ? "buyer" : "seller"}`,
        geocode_request_uuid
      );
    }
    const data = {
      google_response,
      lead_type: router.query.leadType,
      geocode_input: currentAddress.current,
      geocode_request_uuid: geocode_request_uuid,
    };
    return fetch(
      generateApiUrl("/funnel/record_geocode"),
      POST_CONFIG({ data })
    );
  };

  const onSubmit = (e) => {
    if (!buttonDisabledRef.current) {
      buttonDisabledRef.current = true;
      handleFinish();
    }
  };

  const setZipRequiredAddress = (addr) => {
    if (addr) {
      const { number, street } = parser.parseLocation(addr);
      if (number) {
        saveValue("streetNumber", number);
      }

      saveValue("streetName", number ? addr.replace(number, "").trim() : addr);
    }
  };

  const addressHasTwoCharacterGroups = () => {
    let addr = document.getElementById("autocomplete")?.value;

    if (needsZip) {
      addr = document.getElementById("street-address-input").value;
    }

    const splitAddress = addr?.split(" ");
    let numCharacterGroups = 0;

    splitAddress.forEach((characterGroup) => {
      if (characterGroup) {
        numCharacterGroups += 1;
      }
    });

    const hasTwoCharacterGroups = numCharacterGroups > 1;

    return hasTwoCharacterGroups;
  };

  const handleFinish = async (runNumber = 0) => {
    buttonDisabledRef.current = false;
    const property = getValue("property");
    const hasZip =
      (router.query.step === "address" && getValue("zip")) ||
      leadType === "buyer";

    const needsGeocodeZip =
      router.query.step === "address" &&
      !!zipBackup &&
      !geocodedZipBackup.current &&
      leadType !== "buyer";

    if (needsZip && (!getValue("zip") || !address)) {
      setError(t("signup02.address-autocomplete.location_error"));
      return;
    }

    const hasTwoCharacterGroups = addressHasTwoCharacterGroups();
    if (!hasTwoCharacterGroups) {
      setError(t("signup02.address-autocomplete-buy.no_location"));
      return;
    }

    if (
      (router.query.step === "location" && !getValue("address")) ||
      (router.query.step === "also-buying" && !getValue("buyer_address"))
    ) {
      const buyerAutofill = document.getElementById("autocomplete").value;
      const callback = (results, status) => {
        if (status === "OK") {
          const place = results[0];
          const logicalPlaces = translateGoogleToAddress(place);
          createFunnelEvent({
            eventType: "buyer_geocode",
            step: "location",
            leadType: router.query.leadType,
            details: {
              results,
              input: address,
              translatedResults: logicalPlaces,
              geocodedString: buyerAutofill,
            },
          });
          onPlaceSelected(place, (runNumber += 1), true);
        } else {
          setError(t("signup02.address-autocomplete-buy.no_location"));
        }
      };

      if (!runNumber) {
        geocode(`${buyerAutofill}`, callback);
      } else {
        setError(t("signup02.address-autocomplete-buy.no_location"));
      }
    } else if (needsGeocodeZip) {
      const callback = (results, status) => {
        if (status === "OK") {
          const place = results[0];
          const logicalPlaces = translateGoogleToAddress(place);
          createFunnelEvent({
            eventType: "zip_geocode",
            step: "location",
            leadType: router.query.leadType,
            details: {
              results,
              translatedResults: logicalPlaces,
              input: address,
              geocodedString: `zipcode ${zipBackup}`,
            },
          });
          const notUsaOrCa = !["us", "ca"].includes(
            logicalPlaces.country.toLowerCase()
          );
          if (notUsaOrCa) {
            setError(t("signup02.address-autocomplete.usaorca"));
          } else {
            geocodedZipBackup.current = true;
            onPlaceSelected(place, (runNumber += 1), true);
          }
        } else {
          setError(t("signup02.address-autocomplete-buy.no_location"));
        }
      };

      if (!runNumber) {
        geocode(`zipcode ${zipBackup}`, callback);
      } else {
        setError(t("signup02.address-autocomplete-buy.no_location"));
      }
    } else if (
      property === CONDO_ENUM &&
      !unitRef?.current?.value &&
      !unitFocusRef.current
    ) {
      unitFocusRef.current = true;
      unitRef.current.focus();
    } else if (!hasZip) {
      const address = document.getElementById("autocomplete").value;
      const callback = (results, status) => {
        if (status === "OK") {
          const place = results[0];

          const logicalPlaces = translateGoogleToAddress(place);
          createFunnelEvent({
            eventType: "address_geocode_no_zip",
            step: "location",
            leadType: router.query.leadType,
            details: {
              results,
              input: address,
              translatedResults: logicalPlaces,
              geocodedString: address,
            },
          });
          onPlaceSelected(place, (runNumber += 1), true, true);
        } else {
          setNeedsZip(true);
          setZipRequiredAddress(address);
          createFunnelRequest({
            page: ASSET_BASE_URL + router.asPath.split("?")[0] + "/zip",
            request_type: "GET",
          });
        }
      };

      if (runNumber) {
        setNeedsZip(true);
        setZipRequiredAddress(address);
        createFunnelRequest({
          page: ASSET_BASE_URL + router.asPath.split("?")[0] + "/zip",
          request_type: "GET",
        });
      } else {
        let geoCodeAddress = address;
        if (getValue("streetName") && getValue("city") && getValue("state")) {
          geoCodeAddress = `${getValue("streetName")} ${getValue(
            "city"
          )}, ${getValue("state")}`;
        }

        geocode(geoCodeAddress, callback);
      }
    } else {
      if (!pushedToNextStep.current) {
        pushedToNextStep.current = true;

        if (property === CONDO_ENUM && unitRef.current?.value) {
          saveValue("unitNumber", unitRef.current.value);
        }

        let parameters = {
          city: getValue("city"),
          state: getValue("state"),
          streetName: getValue("streetName"),
          streetNumber: getValue("streetNumber"),
          zip: getValue("zip"),
          country: getValue("country"),
        };

        if (isBuyerPath) {
          parameters = {
            city: getValue("city"),
            state: getValue("state"),
            country: getValue("country"),
          };
        }

        await createFunnelRequest({
          page: ASSET_BASE_URL + router.asPath.split("?")[0],
          request_type: "POST",
          parameters,
        });

        if (isSellerPath) {
          setLoading(true);
          setTimeout(() => {
            onStepCompleted && onStepCompleted(legaleseRef.current?.innerText);
            setLoading(false);
          }, 500);
        } else {
          onStepCompleted && onStepCompleted(legaleseRef.current?.innerText);
        }
      }
    }
  };

  const onBackupChange = (e, valueKey, setValue) => {
    const value = e.target.value;

    setValue(value);
    saveValue(valueKey, value);
  };

  const propertyType = useMemo(() => getValue("property"), []);
  const showCondo =
    { seller: true, both: true }[leadType] && propertyType === CONDO_ENUM;

  return (
    <>
      {router.query.leadType === "buyer" && isMobile && (
        <div
          className={[styles.buyerMargin, css(aphStyles.buyerMargin)].join(" ")}
        ></div>
      )}
      <div id="map"></div>
      {needsZip ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transform: "translateX(0px)",
            maxWidth: 685,
            margin: "0 auto",
          }}
          transition={{ duration: 0.75 }}
        >
          <p className={[styles.description, styles.newDescription].join(" ")}>
            {t("signup02.address-autocomplete.location_error")}
          </p>
          <div className={[contactStyles.inputs, styles.inputs].join(" ")}>
            <input
              id="street-address-input"
              className={[styles.input, styles.leftInput, styles.inputNew].join(
                " "
              )}
              value={address}
              required
              onChange={(e) => {
                setError("");
                onBackupChange(e, "address", setAddress);
                setZipRequiredAddress(e.target.value);
              }}
              placeholder={t("signup02.address-autocomplete.street_address")}
            ></input>
            <input
              className={[styles.input, styles.inputNew].join(" ")}
              value={zipBackup}
              required
              onChange={(e) => {
                setError("");
                onBackupChange(e, "zip", setZipBackup);
              }}
              placeholder={t("signup02.address-autocomplete.zip")}
            ></input>
          </div>
        </motion.div>
      ) : (
        <div
          className={[
            styles.autoCompleteWrapper,
            styles.autoCompleteWrapperNew,
          ].join(" ")}
        >
          <div className={styles.addressTagMobileOnly}>
            {isSellerPath
              ? t("signup02.address-autocomplete.address")
              : t("signup02.address-autocomplete-buy.location")}
          </div>
          <GoogleAutocomplete
            onPlaceSelected={(place) => onPlaceSelected(place, 0, false)}
            defaultValue={defaultValue}
            isSellerPath={isSellerPath}
            setAddress={(address) => {
              setAddress(address);

              if (router.query.step === "also-buying") {
                saveValue("buyer_address", address);
              } else {
                saveValue("address", address);
              }
            }}
            showCondo={showCondo}
            setDropdownResults={(results) => {
              curDropdownResults.current = results;
            }}
            isMobile={isMobile}
            autoComplete={
              !isMobile
                ? "off"
                : leadType === "seller"
                ? "street-address"
                : "address-level2"
            }
            key={defaultValue}
            onChange={(e) => {
              if (router.query.leadType !== "both") {
                resetAddressInfo();
              }
              setError("");
              currentAddress.current = e.target.value;
              let value = e.target.value;
              setAddress(value);
            }}
          />
          {showCondo && (
            <input
              placeholder={t("signup02.address-autocomplete.suite")}
              className={[styles.input, styles.apt, styles.inputNew].join(" ")}
              defaultValue={defaultCondo}
              ref={unitRef}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  onSubmit();
                }
              }}
              required
            ></input>
          )}
        </div>
      )}

      {error && (
        <p className={[styles.error, styles.newError].join(" ")}>{error}</p>
      )}
      <div className={css(isSubmit && aphStyles.contain, aphStyles.marginAuto)}>
        <div
          className={[
            panelStyles["Panel__Controls"],
            panelStyles["--new"],
            isSubmit ? panelStyles["--submit"] : "",
          ].join(" ")}
        >
          {isSubmit ? (
            <>
              <Button
                onClick={onSubmit}
                disabled={loading}
                className={css(aphStyles.acceptButton)}
              >
                {loading ? (
                  <i
                    className="fa-solid fa-spinner-third fa-spin"
                    style={{ color: "#fff" }}
                  ></i>
                ) : (
                  t("accept")
                )}
              </Button>
            </>
          ) : (
            <>
              <Button
                type="button"
                tabIndex="-1"
                text={true}
                onClick={prevPage}
              >
                {t("back")}
              </Button>
              <Button onClick={onSubmit} disabled={loading}>
                {loading ? (
                  <i
                    className="fa-solid fa-spinner-third fa-spin"
                    style={{ color: "#fff" }}
                  ></i>
                ) : (
                  t("next")
                )}
              </Button>
            </>
          )}
        </div>
        {isSubmit && <Legalese ref={legaleseRef} contactType="both" />}
      </div>
    </>
  );
}

const aphStyles = StyleSheet.create({
  acceptButton: {
    width: "100%",
  },
  contain: {
    maxWidth: 685,
    margin: "0 auto",
  },
  marginAuto: {
    marginTop: "auto",
  },
  buyerMargin: {
    "@media only screen and (max-width: 1023px)": {
      marginBottom: 16,
    },
  },
});
