import styles from "./LanguageSelector.module.scss";
import { useRouter } from "next/router";

export default function LanguageSelector({}) {
  const router = useRouter();
  const { locale } = router;
  const { pathname, asPath, query } = router;

  const languages = [
    { value: "/", label: "ENG", locale: "en-US" },
    { value: "fr", label: "FR", locale: "fr" },
  ];

  let defaultLanguage = languages[0];
  for (let i = 0; i < languages.length; i++) {
    if (languages[i].locale === locale) {
      defaultLanguage = languages[i];
    }
  }

  const onLanguageChange = (e) => {
    const value = e.target.value;
    const option = languages.find((o) => o.value === value);

    router.push({ pathname, query }, asPath, { locale: option.locale });
  };

  return (
    <select
      className={styles["LanguageSelector"]}
      onChange={onLanguageChange}
      defaultValue={defaultLanguage.value}
    >
      {languages.map((language, index) => {
        return (
          <option value={language.value} key={index}>
            {language.label}
          </option>
        );
      })}
    </select>
  );
}
