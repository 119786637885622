import { getCookie } from "cookies-next";
import { generateApiUrl, POST_CONFIG } from "./fetch";
import { getLeadData } from "./Lead";

export function createFunnelEvent({ details, step, eventType, leadType }) {
  const data = {
    details,
    session_uuid:
      window.localStorage.getItem("sessionUUID") || getCookie("sessionUUID"),
    event_type: eventType,
    funnel_routing_path: leadType,
    step,
  };
  return fetch(
    generateApiUrl("insights/funnel_event"),
    POST_CONFIG({
      data,
    })
  );
}

export function setReferrer(referrer) {
  if (
    !referrer.includes("/compare-agents") &&
    !referrer.includes("/signup02") &&
    !referrer.includes("localhost") &&
    !referrer.includes(process.env.NEXT_PUBLIC_URL_HOST) &&
    (window.localStorage.getItem("referrer") === null ||
      window.localStorage.getItem("referrer") === "") &&
    (referrer !== null || referrer !== "")
  ) {
    window.localStorage.setItem("referrer", referrer);
  }
}

export function createFunnelRequest({
  page,
  request_type,
  sessionUUID,
  leadUUID,
  expid,
  fullUrl,
  server,
  params,
  userAgent,
  responseCode,
  parameters,
}) {
  let adDataJson = {};
  const signup02DataStripped = {};

  if (expid) {
    signup02DataStripped.experiment_id = expid;
  }

  if (!server) {
    const signup02Data = getLeadData();
    signup02Data.experiment_id = expid
      ? expid
      : window.localStorage.getItem("expid");

    for (const [key, value] of Object.entries(signup02Data)) {
      if (value) {
        signup02DataStripped[key] = value;
      }
    }

    adDataJson = getAdData();
  } else {
    adDataJson = getAdDataFromParams(params);
  }

  const session_uuid =
    sessionUUID !== undefined
      ? sessionUUID
      : window.localStorage.getItem("sessionUUID");
  const lead_uuid =
    leadUUID !== undefined ? leadUUID : window.localStorage.getItem("leadUUID");
  const data = {
    session_uuid,
    lead_uuid,
    response_code: responseCode,
    page,
    user_agent: userAgent,
    request_type,
    signup02_data: signup02DataStripped,
    ad_data: adDataJson,
    parameters,
  };

  if (request_type === "SERVER_GET" || responseCode === 404) {
    data.page_load_completed = false;
  }

  if (server) {
    data.full_url = fullUrl;
  } else {
    data.full_url = window.location.href;
  }

  return fetch(
    generateApiUrl("insights/funnel_request"),
    POST_CONFIG({
      data,
      server,
      sessionUUID: session_uuid,
      leadUUID: lead_uuid,
    })
  );
}

const WHITELISTED_AD_PARAMETERS = {
  type_id: true,
  origin: true,
  funnel: true,
  github_revision: true,
  referrer: true,
  gclid: true,
  msclkid: true,
  fbclid: true,
  origin_path: true,
};

export function getAdDataFromParams(params) {
  const paramKeys = Object.keys(params);
  const savedAdData = {
    utm_host: "realestateagents",
  };

  for (let i = 0; i < paramKeys.length; i++) {
    const curKey = paramKeys[i];
    if (curKey.includes("utm_") || WHITELISTED_AD_PARAMETERS[curKey]) {
      savedAdData[curKey] = params[curKey];
    }
  }

  return savedAdData;
}

export function numUTMParams(params) {
  const paramKeys = Object.keys(params);
  let paramCount = 0;
  for (let i = 0; i < paramKeys.length; i++) {
    const curKey = paramKeys[i];
    if (curKey.includes("utm_") || WHITELISTED_AD_PARAMETERS[curKey]) {
      paramCount += 1;
    }
  }

  return paramCount;
}

export function saveAdData(params) {
  // const urlParams = new URLSearchParams(querystring);
  // const entries = urlParams.entries();
  // const params = paramsToObject(entries);
  const paramKeys = Object.keys(params);

  let savedAdData = window.localStorage.getItem("ad_data");

  if (!savedAdData) {
    savedAdData = {
      utm_host: "realestateagents",
    };
  } else {
    savedAdData = JSON.parse(savedAdData);
  }

  for (let i = 0; i < paramKeys.length; i++) {
    const curKey = paramKeys[i];
    if (curKey.includes("utm_") || WHITELISTED_AD_PARAMETERS[curKey]) {
      savedAdData[curKey] = params[curKey];
    }
  }
  window.localStorage.setItem("ad_data", JSON.stringify(savedAdData));
}

export const trackFunnelPartnerAction = ({
  action,
  action_details,
  partner,
  noLeadUUID,
}) => {
  let params = {
    action_details,
    action,
    partner,
  };
  return fetch(
    generateApiUrl("funnel/fpa"),
    POST_CONFIG({ data: params, noLeadUUID })
  );
};

export function getAdData() {
  const adData = window.localStorage.getItem("ad_data");
  let adDataJson = {};
  if (adData) {
    adDataJson = JSON.parse(adData);
  }
  const expid = window.localStorage.getItem("expid");
  if (expid) {
    adDataJson.utm_expid = expid;
  }

  const originPath = window.localStorage.getItem("originPath");
  if (originPath) {
    adDataJson.origin_path = originPath;
  }

  return adDataJson;
}

export const trackFunnelUserAction = ({
  action,
  reference_type,
  reference_id,
  data,
  uuid,
}) => {
  let innerData = {
    funnel: "sem-funnel-v1",
  };
  if (data) {
    innerData = {
      ...innerData,
      ...data,
    };
  }
  let params = {
    action_details: innerData,
    action,
    reference_type,
    reference_id,
  };

  if (uuid) {
    params.uuid = uuid;
  }

  return fetch(generateApiUrl("funnel/fua"), POST_CONFIG({ data: params }));
};
