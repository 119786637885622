import { API_BASE_URL } from "./constants";
import { leadUUID } from "./Lead";

export function generateApiUrl(url) {
  return API_BASE_URL + url;
}

// HTTP Configurations
export const GET_CONFIG = ({ token, server, sessionUUID, leadUUID }) => {
  const headers = setupRequestHeaders(false, server, sessionUUID, leadUUID);
  return {
    method: "GET",
    headers: headers,
  };
};
export const POST_CONFIG = ({
  data,
  server,
  sessionUUID,
  leadUUID,
  noLeadUUID,
}) => {
  const headers = setupRequestHeaders(
    false,
    server,
    sessionUUID,
    leadUUID,
    noLeadUUID
  );
  return {
    method: "post",
    body: JSON.stringify(data),
    headers: headers,
  };
};
export const PUT_CONFIG = ({ data }) => {
  const headers = setupRequestHeaders(false);
  return {
    method: "put",
    body: JSON.stringify(data),
    headers: headers,
  };
};
export const PATCH_CONFIG = ({ data }) => {
  const headers = setupRequestHeaders(false);
  return {
    method: "patch",
    body: JSON.stringify(data),
    headers: headers,
  };
};
export const DELETE_CONFIG = () => {
  const headers = setupRequestHeaders();
  return {
    method: "delete",
    headers: headers,
  };
};

const setupRequestHeaders = (
  noContentType,
  server,
  sessionUUID,
  _leadUUID,
  noLeadUUID
) => {
  let headers = {
    "Content-Type": "application/json",
    "X-Client-Path": !server && window.location.href,
    "session-uuid": server
      ? sessionUUID
      : window.localStorage.getItem("sessionUUID"),
  };

  if (!noLeadUUID) {
    headers["lead-uuid"] =
      server || _leadUUID ? _leadUUID : window.localStorage.getItem("leadUUID");
  }

  if (noContentType) {
    headers = {
      "X-Client-Path": !server && window.location.href,
    };
  }

  return headers;
};
