import styles from "/styles/App.module.css";
import { ASSET_BASE_URL } from "/config/constants";
import Header from "/components/experiments/reskin/organisms//Header";
import Footer from "/components/experiments/reskin/organisms//Footer";

import { useExperiment } from "/contexts/ExperimentContext";

export default function Default({ children, country }) {
  const { experiment } = useExperiment();

  const choseLayout = () => {
    return (
      <div className="reskin">
        <div
          className={styles.app}
          id="app"
          style={{
            minHeight: "100vh",
            maxHeight: "100vh",
            overflow: "auto",
            backgroundImage: `linear-gradient(180deg, #448ACF 0%, #4A96E0 48.5%, rgba(99, 159, 220, 0) 70.1%),url('${ASSET_BASE_URL}/static/images/background.jpg')`,
          }}
        >
          <div className={styles.overlay}></div>
          <Header country={country} />
          <div className={styles.appInner}>{children}</div>
          <Footer />
        </div>
      </div>
    );
  };

  return <>{choseLayout()}</>;
}
