import Head from "next/head";
import Script from "next/script";
import React from "react";
import BugsnagPackage from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { useRouter } from "next/router";

// Components
import Layout from "/components/layouts/Default";

// Styles
import "/styles/globals.scss";
import "../components/steps/contact/styles/react-tel-input.css";
import "../components/steps/contact/styles/contact.css";

// Config
import "../i18";

// Context
import { ExperimentContextProvider } from "/contexts/ExperimentContext";

const BugsnagInit = ({ releaseStage }) => {
  return BugsnagPackage.start({
    apiKey: "1f5f51410360c6de59c1bd7ed38c362e",
    appVersion: `1.2.3-${process.env.NEXT_PUBLIC_ENV}`,
    plugins: [new BugsnagPluginReact(React)],
    enabledReleaseStages: ["production", "staging"],
    releaseStage: releaseStage && releaseStage,
  });
};

const Bugsnag = BugsnagInit({
  releaseStage: process.env.NEXT_PUBLIC_ENV,
});

const ErrorBoundary = Bugsnag.getPlugin("react");

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const getLayout =
    Component.getLayout ||
    ((page) => (
      <Layout country={pageProps?.locationData?.country || "usa"}>
        {page}
      </Layout>
    ));
  const canonicalUrl = (
    `https://www.realestateagents.com/compare-agents` +
    (router.asPath === "/" ? "" : router.asPath)
  ).split("?")[0];

  return (
    <ErrorBoundary>
      <Head>
        <title>Top REALTORS, Top Real Estate Agents</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=no"
        />
        <link rel="canonical" href={canonicalUrl} />
      </Head>
      <Script
        src="https://kit.fontawesome.com/498b7bbafd.js"
        crossOrigin="anonymous"
        async={true}
      ></Script>
      <ExperimentContextProvider>
        {getLayout(<Component {...pageProps} />)}
      </ExperimentContextProvider>
    </ErrorBoundary>
  );
}

export default MyApp;
