import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
  ComboboxOptionText,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { GOOGLE_API_KEY } from "./LocationAutocomplete";
import styles from "./styles/Autocomplete.module.scss";
import useScript from "/utils/useScript";
import { useExperiment } from "../../../../../contexts/ExperimentContext";

export default function GoogleAutocomplete({
  onPlaceSelected,
  onChange,
  autoComplete,
  defaultValue,
  isSellerPath,
  setAddress,
  setDropdownResults,
  isMobile,
  showCondo,
}) {
  const [showDropdownLocal, setShowDropdownLocal] = useState(false);
  const requestOptions = {
    componentRestrictions: { country: ["us", "ca"] },
    types: isSellerPath ? ["address"] : null,
    /* Define search scope here */
  };
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
    init,
  } = usePlacesAutocomplete({
    initOnMount: false,
    callbackName: "initMap",
    defaultValue,
    requestOptions,
    debounce: 100,
  });

  const { experiment } = useExperiment();

  const scriptStatus = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places&language=en`
  );

  useEffect(() => {
    if (scriptStatus === "ready" && !ready) {
      init();
    }
  }, [scriptStatus]);

  const { t } = useTranslation(["translation", "common"]);

  useEffect(() => {
    if (!isMobile) {
      setTimeout(() => {
        const element = document.getElementById("autocomplete");

        element && element.focus();
      }, 100);
    }
  }, []);

  const handleInput = (e) => {
    setShowDropdownLocal(true);
    setValue(e.target.value);
    onChange(e);
  };

  useEffect(() => {
    if (data.length) {
      setDropdownResults(data);
    }
  }, [data, setDropdownResults]);

  const getPlaceData = ({ placeId }) => {
    getGeocode({ placeId }).then((results) => {
      onPlaceSelected && onPlaceSelected(results[0]);
    });
  };

  const handleSelect = (val, i) => {
    let placeId;
    for (let i = 0; i < data.length; i++) {
      if (data[i].description === val) {
        placeId = data[i].place_id;
      }
    }
    setAddress(val);
    setValue(val, false);
    clearSuggestions();
    getPlaceData({ placeId });
  };

  const selectFirstOnEnter = (e) => {
    const optionSelected = document
      .querySelector("[data-reach-combobox-list]")
      .querySelector("[aria-selected=true]");
    if (data.length && (e.which === 13 || e.which === 9) && !optionSelected) {
      setValue(data[0].description);
      setAddress(data[0].description);
      clearSuggestions();
      setShowDropdownLocal(false);
      getPlaceData({ placeId: data[0].place_id });
    }
  };

  // const handleFocus = () => {
  //   if (isMobile) {
  //     const element = document.getElementById("step-box");
  //     const y = element.getBoundingClientRect().top + window.scrollY;
  //     window.scrollTo({ top: y - 16, behavior: "auto" });
  //   }
  // };

  const renderSuggestions = () => {
    const suggestions = data.map(({ place_id, description }, index) => {
      return (
        <ComboboxOption
          key={place_id + `_${index}`}
          index={index}
          value={description}
          className={styles.suggestion}
        >
          <i
            className="fa-solid fa-location-dot"
            style={{ color: "#b8b5b5", marginRight: 8 }}
          ></i>
          <ComboboxOptionText />
        </ComboboxOption>
      );
    });

    return (
      <>
        {suggestions}
        <li className={styles.poweredByGoogle}>
          <img
            src="https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png"
            alt="Powered by Google"
          />
        </li>
      </>
    );
  };

  const showSuggestions = status === "OK" && showDropdownLocal;

  return (
    <div
      className={[styles.container, showCondo ? styles["--unit"] : ""].join(
        " "
      )}
    >
      {/* <Script
        src={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places&callback=initMap`}
      ></Script> */}
      <Combobox onSelect={handleSelect} aria-labelledby="demo">
        <ComboboxInput
          value={value || ""}
          className={[
            styles.autoComplete,
            showCondo && styles.showCondo,
            styles.autoCompleteNew,
          ].join(" ")}
          onChange={handleInput}
          onKeyDown={selectFirstOnEnter}
          autoFocus={!isMobile}
          disabled={!ready}
          id="autocomplete"
          // onFocus={handleFocus}
          defaultValue={defaultValue}
          autoComplete={autoComplete}
          placeholder={
            isSellerPath
              ? t("signup02.address-autocomplete.street_address")
              : t("signup02.address-autocomplete-buy.location")
          }
          selectOnClick
        />
        <ComboboxPopover>
          <ComboboxList
            className={[
              styles.suggestions,
              !showSuggestions && styles.noSuggestions,
            ].join(" ")}
          >
            {showSuggestions && renderSuggestions()}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}
